import Grid from '@material-ui/core/Grid'
import { IGatsbyImageData } from 'gatsby-plugin-image'
import React, { FC } from 'react'

import FeatureCardPerson from '../UI/FeatureCardPerson'
import Wrapper from '../UI/Wrapper'
import { ExecutiveCardsSection } from './styles'

interface IPageCard {
  id: string
  title: string
  url: string
  page_header: [
    {
      id: string
      tags: string[]
      title: string
      feature_image: IGatsbyImageData
      feature_image_mobile: IGatsbyImageData
    },
  ]
}

interface IModuleCard {
  id: string
  title: string
  url: string
  page_header: [
    {
      topic: [
        {
          id: string
          tags: string[]
          job_title: string
          profile_picture: IGatsbyImageData
        },
      ]
    },
  ]
}

interface ExecutiveTeamGridProps {
  cards: (IPageCard | IModuleCard)[]
}

const ExecutiveTeamGrid: FC<ExecutiveTeamGridProps> = ({ cards }) => (
  <ExecutiveCardsSection
    className={cards?.[0]?.page_header?.[0]?.id ? 'padded' : ''}
  >
    <Wrapper>
      <Grid className="cardGrid" component="ul" container>
        {cards?.map((card: any) => (
          <FeatureCardPerson
            jobTitle={
              card.page_header?.[0]?.topic?.[0]?.job_title ||
              card.page_header?.[0]?.title
            }
            key={card.id}
            linkTarget={card.url}
            name={card.page_header?.[0]?.topic?.[0]?.name || card.title}
            profilePicture={
              card.page_header?.[0]?.topic?.[0]?.profile_picture ||
              card.page_header?.[0]?.feature_image_mobile ||
              card.page_header?.[0]?.feature_image
            }
          />
        ))}
      </Grid>
    </Wrapper>
  </ExecutiveCardsSection>
)

export default ExecutiveTeamGrid
