import { graphql, HeadProps, PageProps } from 'gatsby'
import React from 'react'

import ExecutiveTeamGrid from '../components/ExecutiveTeamGrid'
import Hero from '../components/Hero'
import Layout from '../components/Layout'
import { SchemaMarkup } from '../components/SchemaMarkup/SchemaMarkup'
import { SchemaMarkupBreadcrumb } from '../components/SchemaMarkup/SchemaMarkupBreadcrumb'

const ExecutiveTeamPage = (
  props: PageProps<Queries.ExecutiveTeamPageQuery>,
) => {
  const pageData = props?.data?.contentstackPageExecutiveTeam
  const breadcrumbLinks = [{ text: pageData?.title || '' }]

  const metaTitle =
    pageData?.metadata?.page_title ||
    pageData?.title ||
    pageData?.page_header?.[0]?.headline ||
    pageData?.page_header?.[0]?.topic?.[0]?.headline
  const metaDesc =
    pageData?.metadata?.description ||
    pageData?.page_header?.[0]?.subheading ||
    pageData?.page_header?.[0]?.topic?.[0]?.subheading
  const metaImg =
    pageData?.metadata?.image ||
    pageData?.page_header?.[0]?.feature_image ||
    pageData?.page_header?.[0]?.topic?.[0]?.feature_image

  return (
    <Layout
      breadcrumbLinks={breadcrumbLinks}
      description={metaDesc}
      image={metaImg}
      locale={pageData?.publish_details?.locale}
      slug={props.pageContext.slug}
      title={metaTitle}
    >
      <Hero content={pageData?.page_header?.[0]} />
      {pageData?.executive_team?.[0] ? (
        <ExecutiveTeamGrid cards={pageData?.executive_team?.[0]?.executives} />
      ) : null}
    </Layout>
  )
}

export const Head = ({ data }: HeadProps<Queries.ExecutiveTeamPageQuery>) => (
  <>
    <SchemaMarkup data={data.contentstackPageExecutiveTeam?.schema_markup} />
    <SchemaMarkupBreadcrumb
      pageTitle={data?.contentstackPageExecutiveTeam?.title}
    />
  </>
)

export default ExecutiveTeamPage

export const data = graphql`
  query ExecutiveTeamPage($pageId: String, $locale: String) {
    contentstackPageExecutiveTeam(
      id: { eq: $pageId }
      publish_details: { locale: { eq: $locale } }
    ) {
      id
      title
      locale
      chinese_version_url
      publish_details {
        locale
      }
      metadata {
        page_title
        description
        image {
          description
          url
        }
      }
      schema_markup {
        ...SchemaMarkup
      }
      page_header {
        ...PageHeaderSection
      }
      executive_team {
        executives {
          id
          url
          locale
          page_header {
            ... on Contentstack_section_hero_banner {
              id
              topic {
                ... on Contentstack_topic_executive {
                  id
                  name
                  job_title
                  profile_picture {
                    description
                    imgixImage {
                      gatsbyImageData(width: 320, imgixParams: {})
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
