import Typography from '@material-ui/core/Typography'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import React, { FC } from 'react'

import Link from '../../Link'
import { ContentBox, ImageBox, PersonCardWrapper } from './styles'

interface FeatureCardPersonProps {
  name: string
  jobTitle: string
  profilePicture: any
  linkTarget: string
}

const FeatureCardPerson: FC<FeatureCardPersonProps> = ({
  name,
  profilePicture,
  jobTitle,
  linkTarget = '/',
}) => {
  const image = getImage(profilePicture?.imgixImage)
  const altText = profilePicture?.description || ''

  return (
    <PersonCardWrapper>
      <Link directUrl={linkTarget}>
        <>
          <ImageBox>
            {image ? <GatsbyImage alt={altText} image={image} /> : null}
          </ImageBox>
          <ContentBox>
            <Typography className="personName" component="h2" variant="body1">
              <span>{name}</span>
            </Typography>
            <Typography className="jobTitle" component="p" variant="body1">
              {jobTitle}
            </Typography>
          </ContentBox>
        </>
      </Link>
    </PersonCardWrapper>
  )
}

export default FeatureCardPerson
