import styled from 'styled-components'

import { rspTheme } from '../../styles/rspTheme'

export const ExecutiveCardsSection = styled.section`
  padding: 0 0 96px;

  &.padded {
    padding: 96px 0;
  }

  .cardGrid {
    gap: 72px;
    justify-content: center;
    list-style: none;
    padding: 0;
    margin: 0;

    > li {
      flex-basis: 30%;
      flex-grow: 1;
      max-width: 330px;
      min-width: 280px;
      margin: 0;
    }
  }

  @media (max-width: ${rspTheme.breakpoints.values.smmd}px) {
    padding: 0 0 72px;

    .cardGrid {
      gap: 16px;
    }
  }
`
