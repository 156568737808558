import styled from 'styled-components'

import linkTriangle from '../../../images/linkTriangle.svg'
import { rspTheme } from '../../../styles/rspTheme'

export const PersonCardWrapper = styled.li`
  display: block;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.08);
  overflow: hidden;
  transition: box-shadow 0.3s ease-in-out;

  a {
    text-decoration: none;
  }

  &:hover,
  &:focus-within {
    outline: 0px;
    box-shadow: 0px 0px 32px 10px rgba(0, 0, 0, 0.16);
  }
`

export const ContentBox = styled.div`
  padding: 16px;
  color: ${rspTheme.palette.primary.main};

  .jobTitle {
    font-size: 1.6rem;
  }

  .personName {
    font-weight: 600;
    line-height: 1.35;
    font-size: 1.6rem;
    margin-bottom: 4px;
    text-decoration: none;

    span {
      position: relative;
    }

    span::after {
      content: '';
      position: absolute;
      display: block;
      width: 5px;
      height: 9px;
      right: -13px;
      bottom: 6px;
      background-image: url(${linkTriangle});
    }
  }
`

export const ImageBox = styled.div`
  background-color: ${rspTheme.palette.primary.background};
  max-height: 290px;
  overflow: hidden;

  .gatsby-image-wrapper {
    max-height: 100%;
    width: 100%;

    img {
      width: 100%;
    }
  }
`
